import QueryString from "qs";
import dynamic from "next/dynamic";
import Link from "next/link";
import HomeNew from "../components/home-new/home-new";

const Header = dynamic(() => import("../layout/common/header"), { ssr: false });
const Troofooter = dynamic(() => import("../layout/common/troofooter"), {
	ssr: false,
});
// const HomeNew = dynamic(() => import("../components/home-new/home-new"));
// const HomeNew = dynamic(() => import("../components/home-new/home-new"));

// eslint-disable-next-line no-unused-vars
let baseUrl = process.env.NEXT_PUBLIC_REACT_APP_API_BASE_URL;

// export const getServerSideProps = async ({ res }) => {
export const getStaticProps = async () => {
	const query = QueryString.stringify(
		{
			filters: {
				slug: {
					$eq: "home",
				},
			},
			populate: "deep, 8",
		},
		{
			encodeValuesOnly: false,
		},
	);

	const resp = await fetch(`${baseUrl}pages?${decodeURIComponent(query)}`, {
		next: { revalidate: 3600, cache: "force-cache" },
	});
	let ssrdata = await resp.json();

	var listData = ssrdata.data;

	var blocksData = listData[0].attributes.blocks;
	var seoData = listData[0].attributes.seo;
	return {
		props: {
			blocksData,
			seoData,
		},
	};
};

function Home({ blocksData, seoData }) {
	return (
		<div id="root">
			<Header isNew={true} />
			<div className="middle-sction middle-pad">
				{/* <HomePage blocks={blocksData} seo={seoData} /> */}
				<HomeNew blocks={blocksData} seo={seoData} />
			</div>
			<Link href="/sitemap" className="d-none" aria-label="sitemap">
				Site map
			</Link>
			<Troofooter />
		</div>
	);
}

export default Home;
