import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import Image from "next/image";
import { toWebp } from "../../../utils/common";

const Banner = ({ data }) => {

	const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
	const imageUrl = baseUrl + data?.banner_image?.data?.attributes?.url;

	return (
		<>
			<section className={styles["home-banner-section"]}>
				<div className={styles["banner-background"]}>
					<Image
						src={toWebp(imageUrl)}
						alt="Banner background"
						fill
						priority
						quality={80}
						sizes="100vw"
						loading="eager"
						style={{
							objectFit: "cover",
							objectPosition: "center",
						}}
					/>
				</div>

				<Container className={styles["container"]}>
					<Row>
						<Col lg={8} md={8}>
							<div className={styles["banner-content"]}>
								<h1>{data.title}</h1>
								<p>{data.description}</p>

								<ul className={styles["banner-tags"]}>
									{data?.services &&
										data?.services?.map((val, i) => {
											return <li key={i}>{val.label}</li>;
										})}
								</ul>

								<div className="mb-3">
									<a
										href={data.cta.href}
										className="btn btn-primary"
									>
										{data.cta.label1 ?? " Schedule a Call"}
									</a>
								</div>

								<p>
									<a
										href={data.link.href}
										className={styles["banner-txt-link"]}
									>
										<i> {data.link.label}</i>
									</a>
								</p>
							</div>
						</Col>
						<Col lg={4} md={4}>
							<div className={styles["banner-links"]}>
								<ul className={styles["page-link"]}>
									{data?.key_points &&
										data?.key_points?.map((key, i) => {
											return (
												<li key={i}>
													<span
														href={key.href}
														className={
															styles["bannerlink"]
														}
													>
														{key.label}
													</span>
												</li>
											);
										})}
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default Banner;
